<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-star"
    :class="{
      'icon-star-filled': filled,
      'icon-star-hoverable': hoverable,
    }"
  >
    <path
      d="M7 1.23569L7.84524 3.83673C8.113 4.66071 8.88085 5.21859 9.74725 5.21863L12.4822 5.21873L10.2696 6.82637C9.56873 7.33565 9.27543 8.23832 9.54313 9.06232L10.3882 11.6634L8.17551 10.056C7.47456 9.54673 6.52544 9.54673 5.82449 10.056L3.61183 11.6634L4.45687 9.06232C4.72457 8.23832 4.43127 7.33565 3.73036 6.82637L1.51782 5.21873L4.25275 5.21863C5.11914 5.21859 5.887 4.66072 6.15476 3.83673L7 1.23569Z"
      stroke-width="2"
    />
  </svg>
</template>
<script>
export default {
  props: {
    filled: Boolean,
    hoverable: Boolean,
  },
};
</script>
<style lang="scss">
  .icon-star {
    fill: #2A2E52;
    stroke: #2A2E52;
    opacity: 0.1;
  }
  .icon-star-filled {
    fill: rgb(255, 174, 0);
    stroke: rgb(255, 174, 0);
    opacity: 1;
  }
  .icon-star-hoverable {
    opacity: 0.5;
  }
  .icon-star-hoverable:hover ~ .icon-star-hoverable {
    opacity: 0.1;
  }
</style>